import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PayPal from "../../components/PayPal";
import "./index.css";

function InvoiceDetails() {
  const { guid } = useParams(); // Extract the GUID from the URL
  const [invoice, setInvoice] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the invoice details from the Django backend
    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/invoice/${guid}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch invoice details.");
        }
        return response.json();
      })
      .then((data) => {
        setInvoice(data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [guid]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!invoice) {
    return <div>Loading...</div>;
  }

  return (
    <>
        <div className="container">
            <h1>Invoice Details</h1>
            <p><strong>ID:</strong> {invoice.id}</p>
            <p><strong>Amount Due:</strong> {invoice.amount_due}</p>
            <p><strong>Date:</strong> {invoice.due_date}</p>
            <p><strong>Status:</strong> {invoice.status}</p>
            <p>
              <PayPal invoice={invoice} />
            </p>
        </div>
    </>
  );
}

export default InvoiceDetails;
